const cookieValue = (key: string): string | null => {
  if (document.cookie.split(";").some((item) => item.trim().startsWith(`${key}=`))) {
    // eslint-disable-next-line radix
    return document.cookie
      .split("; ")
      .find((row) => row.startsWith(key))!
      .split("=")[1];
  }
  return null;
};

export default cookieValue;
