import { defineStore } from "pinia";
import { ref } from "vue";
import api from "../api/api";
import parseCookies from "../utils/helpers/parseCookies";
import countriesConfig from "../utils/helpers/countriesConfig";
import i18n from "../i18n";

export const useGlobalStore = defineStore("globalStore", () => {
  const countryCode = ref<string | null>("PL");
  const currentCountryConfig = ref();
  const confirmEmail = async (confirmationToken: string) => {
    try {
      await api.confirmEmail(confirmationToken);
    } catch (error: any) {
      throw new Error("error");
    }
  };
  const getCountryCode = async () => {
    countryCode.value = await parseCookies("country");
    let config = countriesConfig.find((config) => config.countryCode === countryCode.value);

    if (!config) {
      countryCode.value = "PL";
      config = countriesConfig.find((config) => config.countryCode === countryCode.value);
    }

    currentCountryConfig.value = config;
  };
  const setInitialLanguage = () => {
    const lang = parseCookies("lang");
    i18n.global.locale.value = lang;
  };

  return {
    confirmEmail,
    currentCountryConfig,
    getCountryCode,
    setInitialLanguage,
  };
});
